import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import QuoteButton from "./QuoteButton";
import data from "./MockData";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "quote-button"
    }}>{`Quote Button`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={QuoteButton} mdxType="Props" />
    <h2 {...{
      "id": "insurance-types"
    }}>{`Insurance Types`}</h2>
    <ul>
  <li>Auto</li>
  <li>Home</li>
  <li>Renters</li>
    </ul>
    <h2 {...{
      "id": "targeted-quote-basic-usage"
    }}>{`Targeted Quote Basic Usage`}</h2>
    <p>{`Structure defined for `}<strong parentName="p">{`company`}</strong>{` parameter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const data = {
  company: 'Allstate',
  insuranceUrl: 'https://www.allstate.com/auto-insurance.aspx'
};
`}</code></pre>
    <h3 {...{
      "id": "auto-insurance"
    }}>{`Auto Insurance`}</h3>
    <Playground __position={2} __code={'<QuoteButton type=\"auto\" displayText=\"get online auto quote\" company={data} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuoteButton,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuoteButton type="auto" displayText="get online auto quote" company={data} mdxType="QuoteButton" />
    </Playground>
    <h3 {...{
      "id": "home-insurance"
    }}>{`Home Insurance`}</h3>
    <Playground __position={3} __code={'<QuoteButton type=\"home\" displayText=\"get online home quote\" company={data} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuoteButton,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuoteButton type="home" displayText="get online home quote" company={data} mdxType="QuoteButton" />
    </Playground>
    <h3 {...{
      "id": "renters-insurance"
    }}>{`Renters Insurance`}</h3>
    <Playground __position={4} __code={'<QuoteButton\n  type=\"renters\"\n  displayText=\"get online renters quote\"\n  company={data}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuoteButton,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuoteButton type="renters" displayText="get online renters quote" company={data} mdxType="QuoteButton" />
    </Playground>
    <h2 {...{
      "id": "general-quote-basic-usage"
    }}>{`General Quote Basic Usage`}</h2>
    <p>{`Available for cases in which the company is not defined.`}</p>
    <h3 {...{
      "id": "auto-insurance-1"
    }}>{`Auto insurance`}</h3>
    <Playground __position={5} __code={'<QuoteButton type=\"auto\" displayText=\"get online auto quote\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuoteButton,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuoteButton type="auto" displayText="get online auto quote" mdxType="QuoteButton" />
    </Playground>
    <h3 {...{
      "id": "home-insurance-1"
    }}>{`Home insurance`}</h3>
    <Playground __position={6} __code={'<QuoteButton type=\"home\" displayText=\"get online home quote\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuoteButton,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuoteButton type="home" displayText="get online home quote" mdxType="QuoteButton" />
    </Playground>
    <h3 {...{
      "id": "renters-insurance-1"
    }}>{`Renters insurance`}</h3>
    <Playground __position={7} __code={'<QuoteButton type=\"renters\" displayText=\"get online renters quote\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuoteButton,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuoteButton type="renters" displayText="get online renters quote" mdxType="QuoteButton" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      